import { EntityConfig, entityFactory } from "../hooks/useEntity";
import { ROLE } from "../Auth/useRole";
import { faFolder } from "@fortawesome/pro-light-svg-icons";
import FaIcon from "../components/FaIcon";
import React from "react";

const conf: EntityConfig = {
  ...entityFactory("flows"),
  endpoint: "user-wizard",
  roleView: [ROLE.CUSTOMER_VIEW, ROLE.CUSTOMER_ADMIN],
  roleAdmin: [ROLE.CUSTOMER_VIEW],
  singularName: (t) => t("Flow"),
  name: (t) => t("Flows"),
  disableCache: true,
  keepPreviousData: true,
  icon: <FaIcon icon={faFolder} size={"lg"} />,
};

export default conf;
