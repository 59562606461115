import { Button } from "antd";
import React from "react";
import Logo from "../../components/utility/Logo";
import Version from "../../components/Version";
import LegalStuff from "../../components/LegalStuff";
import useApp from "../../hooks/useApp";
import { faBars } from "@fortawesome/pro-light-svg-icons";
import emz from "../../image/emz.svg";
import Menu from "./Menu";
import FaIcon from "../../components/FaIcon";
import classNames from "classnames";

/**
 * Application side bar
 */
function Sidebar() {
  const { appData, toggleCollapsed } = useApp();

  const isCollapsed: boolean = appData?.collapsed ?? false;

  function toggleSidebar() {
    if (isCollapsed) {
      toggleCollapsed();
    }
  }
  const width = isCollapsed ? 60 : 240;
  return (
    <aside
      className={classNames(
        {
          "collapsed": isCollapsed,
        },
        `flex flex-col fixed top-0 left-0 h-full bg-white  overflow-auto justify-between`
      )}
      style={{ width }}
      onClick={toggleSidebar}
    >
      <div className={"flex flex-col"}>
        <div className={`flex ${isCollapsed ? "flex-col" : "flex-row"} items-center justify-between p-2`}>
          <Button
            className={"burger"}
            icon={<FaIcon icon={faBars} size={"lg"} />}
            type="text"
            onClick={() => toggleCollapsed(true)}
          />
          <div className={isCollapsed ? "w-7" : "w-25"}>
            <Logo collapsed={isCollapsed} />
          </div>
        </div>

        <Menu collapsed={isCollapsed} />
        <div className={isCollapsed ? "w-full justify-center flex flex-row flex-1 " : "ml-7 mt-5"}>
          <LegalStuff />
          {!isCollapsed && <Version />}
        </div>
      </div>
      <div className={`flex h-24 flex-row items-center w-full ${isCollapsed ? "justify-center" : "ml-7 pt-5"}`}>
        <img alt={"company logo"} className={"company-logo"} src={emz} width={isCollapsed ? 50 : 90} />
      </div>
    </aside>
  );
}

export default Sidebar;
