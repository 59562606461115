import { AutoComplete } from "antd";
import { sinkEvent } from "../../helpers/utils";
import {
  faBell,
  faBookmark,
  faBookmarkSlash,
  faChartPie,
  faCog,
  faPersonRunning,
  faSearch,
  faShieldCheck,
  faSlidersV,
  faWrench,
} from "@fortawesome/pro-light-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { EntityConfig } from "../../hooks/useEntity";
import { useTranslation } from "react-i18next";
import useApp from "../../hooks/useApp";
import { useRole } from "../../Auth/useRole";
import Entities from "../../Entities";
import { fetchEGate } from "../../helpers/eGateApi";
import fls from "../../image/fls.svg";
import useLanguageMenu from "../Topbar/useLanguageMenu";
import { useFavourites } from "../../hooks/userFavourites";

import without from "lodash-es/without";
import union from "lodash-es/union";
import styled from "styled-components";
import { useHotkeys } from "react-hotkeys-hook";
import { BaseSelectRef } from "rc-select";
import { SubMenuType } from "antd/es/menu/interface";
import TopbarCustomers from "../Topbar/TopbarCustomers";
import { useLocation } from "react-use";
import MenuComponent from "./MenuComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SearchStyle = styled.div`
  .search-icon {
    svg {
      color: #bfbfbf !important;
    }
    color: #bfbfbf;
  }
`;

interface Props {
  collapsed: boolean;
}
export default function (props: Props) {
  const location = useLocation();
  const { t } = useTranslation();
  const { appData, useGetView, onAfterLogout } = useApp();
  const { isMobile } = useGetView();
  const { hasAnyRole } = useRole();

  const isCollapsed: boolean = appData?.collapsed ?? false;

  const [favourites, setFavouritesData] = useFavourites({ enabled: true });

  const selectedCustomer = favourites?.selectedCustomer;
  const sidebarFavourites = favourites?.sidebarFavourites ?? [];
  async function updateFavourites(newSidebarFavourites: string[]) {
    const payload = {
      sidebarFavourites: newSidebarFavourites,
      selectedCustomer,
      id: "",
    };
    setFavouritesData(payload);
    await fetchEGate("PUT", "users/favourites", {
      data: JSON.stringify(payload),
    });
  }

  async function addItemToPreferences(key: string) {
    return updateFavourites(union(sidebarFavourites, [key]));
  }
  async function removeItemFromPreferences(key: string) {
    return updateFavourites(without(sidebarFavourites, key));
  }

  function filterRights(entityConfig: EntityConfig) {
    const roleView = entityConfig.roleView ?? [];
    const canRender = hasAnyRole(...roleView);
    if (!canRender) {
      return null;
    }
    return !!entityConfig;
  }

  const reports: EntityConfig[] = [
    Entities.transaction,
    Entities.issues,
    {
      key: "map",
      baseKey: "map",
      baseRoute: "map",
      endpoint: "",
      roleView: Entities.container.roleView,
      roleAdmin: Entities.container.roleAdmin,
      name: () => t("Container map"),
      singularName: () => t("Container map"),
    },
    Entities.containerEmptying,
    Entities.transactionCharts,
    Entities.issueCharts,
    Entities.endTest,
    Entities.biDashboards,
    Entities.biDashboardsManagement,
    Entities.emptyingDetectionLogs,
  ].filter(filterRights);

  const configuration = [
    Entities.container,
    Entities.hardwareUnit,
    Entities.contract,
    Entities.customer,
    Entities.nfcCard,
    Entities.firmware,
    Entities.largeList,
    Entities.regionCode,
    Entities.nfcCardBlacklist,
    Entities.containersAccessGroup,
    Entities.address,
    Entities.product,
    Entities.customerFeatures,
    Entities.hardwareCommands,
    Entities.lockTypeAliases,
  ].filter(filterRights);

  const service = [
    Entities.serviceTask,
    Entities.serviceCalls,
    Entities.serviceAppEvents,
    Entities.technician,
    Entities.serviceTaskCategory,
    Entities.serviceTaskCause,
    Entities.serviceTaskMaterial,
    Entities.serviceTaskSolution,
    Entities.systemOperationLogs,
  ].filter(filterRights);

  const socialServices = [Entities.socialServicesRunners, Entities.socialServiceActions].filter(filterRights);

  const presets = [
    Entities.cardReaderGroup,
    Entities.fractionConfigurationGroup,
    Entities.regionCodeConfigurationGroup,
    Entities.intervalConfigurationGroup,
    Entities.emptyingDetection,
    Entities.mifareDataReadFrame,
    Entities.loRaSettings,
  ].filter(filterRights);

  const fillLevel = [
    Entities.fillContainers,
    Entities.fillLevelSensor,
    Entities.taskList,
    Entities.liftingSystem,
    Entities.truck,
    Entities.truckType,
    Entities.truckBody,
  ].filter(filterRights);

  const security = [Entities.user, Entities.permissionGroup, Entities.devices].filter(filterRights);
  const manage = [Entities.appMessage].filter(filterRights);
  const flows = [Entities.flows].filter(filterRights);

  const mobile = useGetView().isMobile;

  const path = location.pathname?.replace("/app/", "").split("/")[0] as string;

  const keyMap = { configuration, presets, security, reports, manage, fillLevel, flows, service, socialServices };

  const openKey: string | undefined = Object.entries(keyMap).find((i) =>
    i[1].find((entConf) => entConf?.baseRoute === path)
  )?.[0];

  const [openKeys, setOpenKeys] = useState<Set<string>>(new Set([openKey as string]));

  useEffect(() => {
    setOpenKeys(new Set([openKey as string]));
  }, [openKey, path]);

  useEffect(() => {
    if (isCollapsed) {
      setOpenKeys(new Set([]));
    }
  }, [isCollapsed]);

  const langMenu = useLanguageMenu();

  function getLabel(i: EntityConfig) {
    const isFav = sidebarFavourites.find((fav) => i.key === fav);
    const showFav = !isCollapsed;

    return (
      <div className={"flex-1 flex flex-row justify-between align-center"}>
        <div className={"menu-link-wrapper"}>{i.name(t)}</div>
        {showFav ? (
          <>
            {isFav ? (
              <FontAwesomeIcon
                className={"invisible group-hover:visible"}
                icon={faBookmarkSlash}
                role={"button"}
                size={"lg"}
                title={t("Unpin")}
                onClick={(e) => {
                  sinkEvent(e);
                  removeItemFromPreferences(i.key);
                }}
              />
            ) : (
              <FontAwesomeIcon
                className={"invisible group-hover:visible"}
                icon={faBookmark}
                role={"button"}
                size={"lg"}
                title={t("Pin")}
                onClick={(e) => {
                  sinkEvent(e);
                  addItemToPreferences(i.key);
                }}
              />
            )}
          </>
        ) : null}
      </div>
    );
  }
  function canRenderEntity(i: EntityConfig) {
    const roleView = i.roleView ?? [];
    return hasAnyRole(...roleView);
  }
  function convert(i: EntityConfig) {
    if (!canRenderEntity(i)) return null;
    return {
      key: i.baseKey,
      icon: i.icon,
      title: i.name(t),
      label: getLabel(i),
      link: `/app/${i.baseRoute}`,
    };
  }
  function convertTopLevel(i: EntityConfig) {
    if (!canRenderEntity(i)) return null;

    return {
      key: i.baseKey,
      icon: i.icon,
      title: i.name(t),
      link: `/app/${i.baseRoute}`,
      label: (
        <Link className={"group-hover:text-white"} to={`/app/${i.baseRoute}`}>
          {i.name(t)}
        </Link>
      ),
    };
  }

  function convertFavourites(i: EntityConfig) {
    if (!canRenderEntity(i)) return null;

    return {
      key: i.baseKey + "_fav",
      icon: i.icon,
      title: i.name(t),
      label: getLabel(i),
    };
  }
  function mobileItem(i: any) {
    if (!mobile) {
      return null;
    }
    return i;
  }
  function convertSub(i: SubMenuType) {
    if (i.children.length === 0) {
      return null;
    }
    return i;
  }

  const [search, setSearch] = useState("");
  const allMenuItems = [
    ...reports,
    ...configuration,
    ...presets,
    ...service,
    ...fillLevel,
    ...security,
    ...manage,
    ...socialServices,
    ...flows,
    ...[Entities.dashboard, Entities.serviceCockpit], // top level
  ];
  const favouritesItems = allMenuItems.filter((entity) => sidebarFavourites?.includes(entity.key));

  const filteredMenuItems = allMenuItems.filter((entity) => {
    if (search === "") {
      return true;
    }
    return entity.name(t).toLowerCase().includes(search.toLowerCase());
  });

  const menuItems = [
    favouritesItems.length
      ? {
          children: favouritesItems.map(convertFavourites),
          label: t("Bookmarked"),
          key: "favourites",
          icon: <FontAwesomeIcon icon={faBookmark} size={"lg"} />,
        }
      : undefined,
    convertTopLevel(Entities.dashboard),
    convertTopLevel(Entities.serviceCockpit),
    convertSub({
      children: reports.map(convert),
      label: t("Reports"),
      key: "reports",
      icon: <FontAwesomeIcon icon={faChartPie} size={"lg"} />,
    }),
    convertSub({
      children: configuration.map(convert),
      label: t("Configuration"),
      key: "configuration",
      icon: <FontAwesomeIcon icon={faCog} size={"lg"} />,
    }),
    convertSub({
      children: service.map(convert),
      label: t("Service"),
      key: "service",
      icon: <FontAwesomeIcon icon={faWrench} size={"lg"} />,
    }),
    convertSub({
      children: socialServices.map(convert),
      label: t("Social service"),
      key: "socialServices",
      icon: <FontAwesomeIcon icon={faPersonRunning} size={"lg"} />,
    }),
    convertSub({
      children: presets.map(convert),
      label: t("Presets"),
      key: "presets",
      icon: <FontAwesomeIcon icon={faSlidersV} size={"lg"} />,
    }),
    convertSub({
      children: fillLevel.map(convert),
      label: t("Fill level"),
      key: "fillLevel",
      icon: (
        <img
          alt={"fill level"}
          className="fak fa-fls fa-lg group-hover:brightness-0 group-hover:invert menu-white-fa-icon"
          height={22}
          src={fls}
          width={22}
        />
      ),
    }),
    convertTopLevel(Entities.flows),
    convertSub({
      children: security.map(convert),
      label: t("Security"),
      key: "security",
      icon: <FontAwesomeIcon icon={faShieldCheck} size={"lg"} />,
    }),
    convertSub({
      children: manage.map(convert),
      label: t("Manage"),
      key: "manage",
      icon: <FontAwesomeIcon icon={faBell} size={"lg"} />,
    }),
    mobileItem({
      label: <Link to={"/app/profile"}>{t("Profile")}</Link>,
      key: "profile",
    }),

    mobileItem({
      label: (
        <div
          onClick={async () => {
            await fetchEGate("GET", "authorize/logout");
            await onAfterLogout();
            navigate("/signin", { replace: true });
          }}
        >
          {t("Logout")}
        </div>
      ),
      key: "logout",
    }),
    mobileItem(langMenu),
  ].filter((i) => i);

  const navigate = useNavigate();
  const ref = useRef<BaseSelectRef>(null);

  useHotkeys("shift + s", () => {
    setTimeout(() => {
      ref.current?.focus();
    }, 100);
  });
  return (
    <>
      <SearchStyle
        className={"mt-1 gap-3 flex flex-col"}
        hidden={isMobile ? false : isCollapsed}
        style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}
      >
        <div>{isMobile ? <TopbarCustomers /> : null}</div>

        <AutoComplete
          ref={ref}
          className={"w100 mb-2"}
          defaultActiveFirstOption
          options={filteredMenuItems.map((i: EntityConfig) => ({
            value: i.key,
            label: i.name(t),
            baseRoute: i.baseRoute,
          }))}
          placeholder={
            <div className={"flex justify-between"}>
              <div>
                <FontAwesomeIcon className={"search-icon mr-1"} icon={faSearch} size={"lg"} />
                <span>{t("Search...")}</span>
              </div>
              <div>{t("shift + s")}</div>
            </div>
          }
          value={search}
          onChange={(v: any, option: any) => {
            setSearch("");

            if (!option?.baseRoute) {
              return;
            }
            navigate("/app/" + option?.baseRoute);
          }}
          onSearch={setSearch}
        />
      </SearchStyle>

      <MenuComponent
        collapsed={props.collapsed}
        items={menuItems}
        openKeys={openKeys}
        selectedKey={path}
        setOpenKeys={setOpenKeys}
      />
    </>
  );
}
